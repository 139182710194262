<script>
import { validationMixin } from '@/mixins/form/validation';
import { ErrorMessage, Field, Form } from 'vee-validate';
import Multiselect from '@vueform/multiselect';

export default {
  name: 'card-form',
  mixins: [validationMixin],
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  props: {
    accounts: {
      type: Array,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        account: null,
        amount: null,
        amount_credited: null,
        payment_type: 0,
        additional: {
          card_number: null,
          cardholder_name: null,
          country_id: null,
          city: null,
          address: null,
        },
      },
    };
  },
  computed: {
    selectedAccount() {
      return this.accounts.find((acc) => acc.value === this.form.account);
    },
  },
  methods: {
    submit(values, actions) {
      this.loading = true;

      this.axios
        .post(`/accounts/${this.form.account}/withdraw`, this.form)
        .then(() => {
          this.$store.dispatch('accounts/getAccounts', true);
          this.$notify({
            type: 'success',
            text: this.$t('Withdraw.OrderSuccessfullyCreated'),
          });

          this.form = {
            account: null,
            amount: null,
            amount_credited: null,
            payment_type: 0,
            additional: {
              card_number: null,
              cardholder_name: null,
              country_id: null,
              city: null,
              address: null,
            },
          };

          this.$refs.form.resetForm();
        })
        .catch(({ response }) => {
          this.handleResponseErrors(response, { values, actions });
        })
        .finally(() => (this.loading = false));
    },
    onAmountBlur() {
      this.form.amount_credited = this.form.amount;
    },
  },
};
</script>

<template>
  <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
    <Form @submit="submit" ref="form">
      <Field name="account" rules="required" :label="$t('Withdraw.CardForm.TransferFrom')" v-slot="{ errors, field }">
        <b-form-group class="mb-3" :label="$t('Withdraw.CardForm.TransferFrom')">
          <Multiselect
            v-model="form.account"
            :options="accounts"
            :label="'text'"
            :rtl="$i18n.locale === 'ar'"
            :class="{ 'is-invalid': errors.length }"
            searchable
            v-bind="field"
          />

          <ErrorMessage name="account" class="invalid-feedback" />
        </b-form-group>
      </Field>

      <div class="row">
        <div class="col-md-6">
          <Field
            name="amount"
            :rules="`required|max_value_account:${form.account === null ? Infinity : selectedAccount.amount}`"
            :label="$t('Withdraw.CardForm.AmountOfPayment')"
            v-slot="{ errors, field }"
          >
            <b-form-group class="mb-3 text-muted" :label="$t('Withdraw.CardForm.AmountOfPayment')" label-for="amount">
              <b-form-input
                id="amount"
                v-model="form.amount"
                type="text"
                :placeholder="$t('Withdraw.CardForm.AmountOfPayment')"
                v-maska="{ mask: 'XZ*', tokens: { X: { pattern: /[1-9]/ }, Z: { pattern: /[0-9]/ } } }"
                :class="{ 'is-invalid': errors.length }"
                v-bind="field"
                @blur="onAmountBlur"
              />

              <ErrorMessage name="amount" class="invalid-feedback" />
            </b-form-group>
          </Field>
        </div>

        <div class="col-md-6">
          <b-form-group
            class="mb-3 text-muted"
            :label="$t('Withdraw.CardForm.AmountCredited')"
            label-for="amount_credited"
          >
            <b-form-input
              id="amount_credited"
              v-model="form.amount_credited"
              :readonly="true"
              type="text"
              :placeholder="$t('Withdraw.CardForm.AmountCredited')"
            />
          </b-form-group>
        </div>
      </div>

      <Field
        name="additional.card_number"
        rules="required"
        :label="$t('Withdraw.CardForm.CardNumber')"
        v-slot="{ errors, field }"
      >
        <b-form-group class="mb-3 text-muted" :label="$t('Withdraw.CardForm.CardNumber')">
          <b-form-input
            v-model="form.additional.card_number"
            type="text"
            placeholder="XXXX XXXX XXXX XXXX"
            v-maska="'#### #### #### ####'"
            :class="{ 'is-invalid': errors.length }"
            v-bind="field"
          />

          <ErrorMessage name="additional.card_number" class="invalid-feedback" />
        </b-form-group>
      </Field>

      <Field
        name="additional.cardholder_name"
        rules="required"
        :label="$t('Withdraw.CardForm.CardHolderName')"
        v-slot="{ errors, field }"
      >
        <b-form-group class="mb-3 text-muted" :label="$t('Withdraw.CardForm.CardHolderName')">
          <b-form-input
            v-model="form.additional.cardholder_name"
            type="text"
            :placeholder="$t('Withdraw.CardForm.CardHolderName')"
            :class="{ 'is-invalid': errors.length }"
            v-bind="field"
          />

          <ErrorMessage name="additional.cardholder_name" class="invalid-feedback" />
        </b-form-group>
      </Field>

      <div class="row">
        <div class="col-md-6">
          <Field
            name="additional.country_id"
            rules="required"
            :label="$t('Withdraw.CardForm.Country')"
            v-slot="{ errors, field }"
          >
            <b-form-group class="mb-3" :label="$t('Withdraw.CardForm.Country')">
              <Multiselect
                v-model="form.additional.country_id"
                :options="countries"
                :label="'text'"
                :rtl="$i18n.locale === 'ar'"
                :class="{ 'is-invalid': errors.length }"
                searchable
                v-bind="field"
              />

              <ErrorMessage name="additional.country_id" class="invalid-feedback" />
            </b-form-group>
          </Field>
        </div>

        <div class="col-md-6">
          <Field
            name="additional.city"
            rules="required"
            :label="$t('Withdraw.CardForm.City')"
            v-slot="{ errors, field }"
          >
            <b-form-group class="mb-3" :label="$t('Withdraw.CardForm.City')">
              <b-form-input
                v-model="form.additional.city"
                type="text"
                :placeholder="$t('Withdraw.CardForm.City')"
                :class="{ 'is-invalid': errors.length }"
                v-bind="field"
              />

              <ErrorMessage name="additional.city" class="invalid-feedback" />
            </b-form-group>
          </Field>
        </div>
      </div>

      <Field name="additional.address" :label="$t('Withdraw.CardForm.RecipientAddress')" v-slot="{ errors, field }">
        <b-form-group class="mb-3 text-muted" :label="$t('Withdraw.CardForm.RecipientAddress')">
          <b-form-input
            v-model="form.additional.address"
            type="text"
            :placeholder="$t('Withdraw.CardForm.RecipientAddress')"
            :class="{ 'is-invalid': errors.length }"
            v-bind="field"
          />

          <ErrorMessage name="additional.address" class="invalid-feedback" />
        </b-form-group>
      </Field>

      <div class="text-center mt-4 mb-5">
        <button type="submit" class="btn btn-dark btn-sm w-lg">{{ $t('Common.Continue') }}</button>
      </div>
    </Form>
  </b-overlay>
</template>
