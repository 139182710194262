<script>
import { validationMixin } from '@/mixins/form/validation';
import { ErrorMessage, Field, Form } from 'vee-validate';
import Multiselect from '@vueform/multiselect';

export default {
  name: 'crypto-form',
  mixins: [validationMixin],
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  props: {
    accounts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      crypto_currencies: ['BTC', 'ETH', 'USDT'],
      crypto_networks: ['TRC20', 'ERC20'],
      form: {
        account: null,
        amount: null,
        payment_type: 2,
        additional: {
          crypto_wallet_address: null,
          crypto_currency: 'BTC',
          crypto_network: null,
        },
      },
    };
  },
  computed: {
    selectedAccount() {
      return this.accounts.find((acc) => acc.value === this.form.account);
    },
  },
  methods: {
    submit(values, actions) {
      this.loading = true;

      this.axios
        .post(`/accounts/${this.form.account}/withdraw`, this.form)
        .then(() => {
          this.$store.dispatch('accounts/getAccounts', true);
          this.$notify({
            type: 'success',
            text: this.$t('Withdraw.OrderSuccessfullyCreated'),
          });

          this.form = {
            account: null,
            amount: null,
            payment_type: 2,
            additional: {
              crypto_wallet_address: null,
              crypto_currency: 'BTC',
              crypto_network: null,
            },
          };

          this.$refs.form.resetForm();
        })
        .catch(({ response }) => {
          this.handleResponseErrors(response, { values, actions });
        })
        .finally(() => (this.loading = false));
    },
    onAmountBlur() {
      this.form.amount_credited = this.form.amount;
    },
  },
};
</script>

<template>
  <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
    <Form @submit="submit" ref="form">
      <Field name="account" rules="required" :label="$t('Withdraw.CardForm.TransferFrom')" v-slot="{ errors, field }">
        <b-form-group class="mb-3" :label="$t('Withdraw.CardForm.TransferFrom')">
          <Multiselect
            v-model="form.account"
            :options="accounts"
            :label="'text'"
            :rtl="$i18n.locale === 'ar'"
            :class="{ 'is-invalid': errors.length }"
            searchable
            v-bind="field"
          />

          <ErrorMessage name="account" class="invalid-feedback" />
        </b-form-group>
      </Field>

      <Field
        name="amount"
        :rules="`required|max_value_account:${form.account === null ? Infinity : selectedAccount.amount}`"
        :label="$t('Withdraw.CardForm.AmountOfPayment')"
        v-slot="{ errors, field }"
      >
        <b-form-group class="mb-3 text-muted" :label="$t('Withdraw.CardForm.AmountOfPayment')" label-for="amount">
          <b-form-input
            id="amount"
            v-model="form.amount"
            type="text"
            :placeholder="$t('Withdraw.CardForm.AmountOfPayment')"
            v-maska="{ mask: 'XZ*', tokens: { X: { pattern: /[1-9]/ }, Z: { pattern: /[0-9]/ } } }"
            :class="{ 'is-invalid': errors.length }"
            v-bind="field"
            @blur="onAmountBlur"
          />

          <ErrorMessage name="amount" class="invalid-feedback" />
        </b-form-group>
      </Field>

      <Field
        name="additional.crypto_currencies"
        :label="$t('Withdraw.CryptoForm.CryptoCurrency')"
        v-slot="{ errors, field }"
      >
        <b-form-group class="mb-3" :label="$t('Withdraw.CryptoForm.CryptoCurrency')">
          <Multiselect
            v-model="form.additional.crypto_currency"
            :options="crypto_currencies"
            :value="'BTC'"
            :label="'text'"
            :rtl="$i18n.locale === 'ar'"
            :class="{ 'is-invalid': errors.length }"
            searchable
            :can-deselect="false"
            :can-clear="false"
            v-bind="field"
          />

          <ErrorMessage name="additional.crypto_currencies" class="invalid-feedback" />
        </b-form-group>
      </Field>

      <Field
        name="additional.crypto_wallet_address"
        rules="required"
        :label="$t('Withdraw.CryptoForm.WalletAddress')"
        v-slot="{ errors, field }"
      >
        <b-form-group class="mb-3 text-muted" :label="$t('Withdraw.CryptoForm.WalletAddress')">
          <b-form-input
            v-model="form.additional.crypto_wallet_address"
            type="text"
            :class="{ 'is-invalid': errors.length }"
            v-bind="field"
          />

          <ErrorMessage name="additional.crypto_wallet_address" class="invalid-feedback" />
        </b-form-group>
      </Field>

      <Field
        name="additional.crypto_network"
        :label="$t('Withdraw.CryptoForm.CryptoNetwork')"
        :rules="form.additional.crypto_currency === 'ETH' ? `required` : ``"
        v-slot="{ errors, field }"
      >
        <b-form-group class="mb-3" :label="$t('Withdraw.CryptoForm.CryptoNetwork')">
          <Multiselect
            :disabled="form.additional.crypto_currency !== 'ETH'"
            v-model="form.additional.crypto_network"
            :options="crypto_networks"
            :label="'text'"
            :rtl="$i18n.locale === 'ar'"
            :class="{ 'is-invalid': errors.length }"
            searchable
            v-bind="field"
          />

          <ErrorMessage name="additional.crypto_network" class="invalid-feedback" />
        </b-form-group>
      </Field>

      <div class="text-center mt-4 mb-5">
        <button type="submit" class="btn btn-dark btn-sm w-lg">{{ $t('Common.Continue') }}</button>
      </div>
    </Form>
  </b-overlay>
</template>
