<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import BankForm from '@/components/pages/withdraw/bank-form';
import CryptoForm from '@/components/pages/withdraw/crypto-form';
import CardForm from '@/components/pages/withdraw/card-form';

export default {
  components: {
    Layout,
    PageHeader,
    BankForm,
    CryptoForm,
    CardForm,
  },
  data() {
    return {
      title: this.$t('Withdraw.Title'),
      loading: true,
      type: 'bank',
      accounts: [],
      countries: [],
    };
  },
  methods: {
    loadAccounts() {
      return this.axios.get(`/accounts`).then(({ data }) => {
        this.accounts = data
          .filter((account) => !account.is_demo)
          .map((account) => {
            return {
              value: account.id,
              text: `${account.name} ${account.currency.key}`,
              amount: account.amount,
            };
          });
      });
    },
    loadCountries() {
      return this.$store.dispatch('collection/countries').then((data) => {
        this.countries = data.map((country) => ({
          value: country.id,
          text: country.name,
        }));
      });
    },
  },
  mounted() {
    Promise.all([this.loadAccounts(), this.loadCountries()]).then(() => {
      this.loading = false;
    });
  },
  metaInfo() {
    return {
      title: this.$t('Withdraw.Title'),
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />

    <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
      <div class="card card-transparent mb-4">
        <div class="row">
          <div class="col-12">
            <div class="bg-white">
              <div class="form-title bordered">
                <div class="row justify-content-center">
                  <div class="col-xl-8 col-lg-10">
                    {{ $t('Withdraw.SelectType') }}
                  </div>
                </div>
              </div>

              <div class="row pt-3 pb-3 mb-4 justify-content-center">
                <div class="col-xl-8 col-lg-10">
                  <div class="row">
                    <div class="col-sm-4 col-12">
                      <div class="mb-3">
                        <label class="card-radio-label mb-2">
                          <input type="radio" name="currency" class="card-radio-input" value="bank" v-model="type" />

                          <div class="card-radio">
                            <div>
                              <i class="bx bx-money font-size-24 text-dark align-middle me-2"></i>
                              <span>{{ $t('Withdraw.BankTransfer') }}</span>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>

                    <div class="col-sm-4 col-12">
                      <div class="mb-3">
                        <label class="card-radio-label mb-2">
                          <input type="radio" name="currency" class="card-radio-input" value="crypto" v-model="type" />

                          <div class="card-radio">
                            <div>
                              <i class="bx bx-bitcoin font-size-24 text-dark align-middle me-2"></i>
                              <span>{{ $t('Withdraw.CryptoCurrency') }}</span>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>

                    <div class="col-sm-4 col-12">
                      <div class="mb-3">
                        <label class="card-radio-label mb-2">
                          <input type="radio" name="currency" class="card-radio-input" value="card" v-model="type" />

                          <div class="card-radio">
                            <div>
                              <i class="bx bx-credit-card font-size-24 text-dark align-middle me-2"></i>
                              <span>{{ $t('Withdraw.Card') }}</span>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-md-6">
                  <bank-form v-if="type === 'bank'" :accounts="accounts" :countries="countries" />
                  <crypto-form v-if="type === 'crypto'" :accounts="accounts" />
                  <card-form v-if="type === 'card'" :accounts="accounts" :countries="countries" />
                </div>
              </div>
            </div>
          </div>

          <!-- Hidden unil no texts -->
          <div class="d-none col-xl-3 col-12 description">
            <div class="form-title mb-3">{{ $t('Withdraw.Description') }}</div>

            <img
              v-if="type === 'card'"
              src="@/assets/images/visa-mastercard.png"
              alt="payments"
              class="payments-img mb-4"
            />

            <p class="mb-4">Transfered funds will immediately be converted and credited to your trading account.</p>

            <div class="form-title mb-3">FAQ</div>

            <div class="accordion">
              <div v-for="index in 3" :key="index" class="faq-item">
                <button class="accordion-button fw-medium" type="button" v-b-toggle="`faq-${index}`">
                  {{ index }}. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </button>

                <b-collapse :id="`faq-${index}`" accordion="faq" role="tabpanel">
                  <b-card-body>
                    <b-card-text
                      >Depositing, withdrawal and transferring funds operations are made according to the company's
                      rates relevant as of the date of receipt of applications.</b-card-text
                    >
                  </b-card-body>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style scoped lang="scss">
.payments-img {
  width: 126px;
}

.accordion-button {
  color: #13172b;
  font-weight: 400;
}

.description {
  > * {
    @media (max-width: 1200px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
</style>
