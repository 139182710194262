<script>
import { validationMixin } from '@/mixins/form/validation';
import { ErrorMessage, Field, Form } from 'vee-validate';
import Multiselect from '@vueform/multiselect';

export default {
  name: 'bank-form',
  mixins: [validationMixin],
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  props: {
    accounts: {
      type: Array,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      transferTypes: [
        { text: 'IBAN', value: 1 },
        { text: 'SWIFT', value: 4 },
      ],
      form: {
        account: null,
        amount: null,
        amount_credited: null,
        payment_type: 1,
        additional: {
          swift_code: null,
          account_number: null,
          full_name: null,
          iban: null,
          bank_name: null,
          country_id: null,
          city: null,
          address: null,
        },
      },
    };
  },
  computed: {
    selectedAccount() {
      return this.accounts.find((acc) => acc.value === this.form.account);
    },
  },
  methods: {
    submit(values, actions) {
      this.loading = true;

      const params =
        this.form.payment_type === 1
          ? {
              ...this.form,
              additional: {
                iban: this.form.additional.iban,
                full_name: this.form.additional.full_name,
                country_id: this.form.additional.country_id,
                city: this.form.additional.city,
                address: this.form.additional.address,
              },
            }
          : {
              ...this.form,
              additional: {
                swift_code: this.form.additional.swift_code,
                account_number: this.form.additional.account_number,
                bank_name: this.form.additional.bank_name,
                country_id: this.form.additional.country_id,
                city: this.form.additional.city,
                address: this.form.additional.address,
              },
            };

      this.axios
        .post(`/accounts/${this.form.account}/withdraw`, params)
        .then(() => {
          this.$store.dispatch('accounts/getAccounts', true);
          this.$notify({
            type: 'success',
            text: this.$t('Withdraw.OrderSuccessfullyCreated'),
          });

          this.form = {
            account: null,
            amount: null,
            amount_credited: null,
            additional: {
              swift_code: null,
              account_number: null,
              bank_name: null,
              full_name: null,
              iban: null,
              country_id: null,
              city: null,
              address: null,
            },
          };

          this.$refs.form.resetForm();
        })
        .catch(({ response }) => {
          this.handleResponseErrors(response, { values, actions });
        })
        .finally(() => (this.loading = false));
    },
    onAmountBlur() {
      this.form.amount_credited = this.form.amount;
    },
  },
};
</script>

<template>
  <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
    <b-form-group class="mb-3" :label="$t('Withdraw.CardForm.TransferType')">
      <Multiselect
        v-model="form.payment_type"
        :options="transferTypes"
        :label="'text'"
        :rtl="$i18n.locale === 'ar'"
        :can-deselect="false"
        :can-clear="false"
      />
    </b-form-group>

    <Form @submit="submit" ref="form">
      <Field name="account" rules="required" :label="$t('Withdraw.CardForm.TransferFrom')" v-slot="{ errors, field }">
        <b-form-group class="mb-3" :label="$t('Withdraw.CardForm.TransferFrom')">
          <Multiselect
            v-model="form.account"
            :options="accounts"
            :label="'text'"
            :rtl="$i18n.locale === 'ar'"
            :class="{ 'is-invalid': errors.length }"
            searchable
            v-bind="field"
          />

          <ErrorMessage name="account" class="invalid-feedback" />
        </b-form-group>
      </Field>

      <div class="row">
        <div class="col-md-6">
          <Field
            name="amount"
            :rules="`required|max_value_account:${form.account === null ? Infinity : selectedAccount.amount}`"
            :label="$t('Withdraw.CardForm.AmountOfPayment')"
            v-slot="{ errors, field }"
          >
            <b-form-group class="mb-3 text-muted" :label="$t('Withdraw.CardForm.AmountOfPayment')" label-for="amount">
              <b-form-input
                id="amount"
                v-model="form.amount"
                type="text"
                :placeholder="$t('Withdraw.CardForm.AmountOfPayment')"
                v-maska="{ mask: 'XZ*', tokens: { X: { pattern: /[1-9]/ }, Z: { pattern: /[0-9]/ } } }"
                :class="{ 'is-invalid': errors.length }"
                v-bind="field"
                @blur="onAmountBlur"
              />

              <ErrorMessage name="amount" class="invalid-feedback" />
            </b-form-group>
          </Field>
        </div>

        <div class="col-md-6">
          <b-form-group
            class="mb-3 text-muted"
            :label="$t('Withdraw.CardForm.AmountCredited')"
            label-for="amount_credited"
          >
            <b-form-input
              id="amount_credited"
              v-model="form.amount_credited"
              :readonly="true"
              type="text"
              :placeholder="$t('Withdraw.CardForm.AmountCredited')"
            />
          </b-form-group>
        </div>
      </div>

      <!-- SWIFT form -->
      <template v-if="form.payment_type === 4">
        <Field
          name="form.additional.bank_name"
          rules="required|max:100"
          :label="$t('Withdraw.CardForm.BankName')"
          v-slot="{ errors, field }"
        >
          <b-form-group class="mb-3 text-muted" :label="$t('Withdraw.CardForm.BankName')">
            <b-form-input
              v-model="form.additional.bank_name"
              type="text"
              :placeholder="$t('Withdraw.CardForm.BankName')"
              :class="{ 'is-invalid': errors.length }"
              v-bind="field"
            />

            <ErrorMessage name="form.additional.bank_name" class="invalid-feedback" />
          </b-form-group>
        </Field>

        <Field
          name="form.additional.swift_code"
          rules="required|swift"
          :label="$t('Withdraw.CardForm.SwiftCode')"
          v-slot="{ errors, field }"
        >
          <b-form-group class="mb-3 text-muted" :label="$t('Withdraw.CardForm.SwiftCode')">
            <b-form-input
              v-model="form.additional.swift_code"
              type="text"
              :placeholder="$t('Withdraw.CardForm.SwiftCode')"
              :class="{ 'is-invalid': errors.length }"
              v-bind="field"
            />

            <ErrorMessage name="form.additional.swift_code" class="invalid-feedback" />
          </b-form-group>
        </Field>

        <Field
          name="form.additional.account_number"
          rules="required|min:14|max:14"
          :label="$t('Withdraw.CardForm.AccountNumber')"
          v-slot="{ errors, field }"
        >
          <b-form-group class="mb-3 text-muted" :label="$t('Withdraw.CardForm.AccountNumber')">
            <b-form-input
              v-model="form.additional.account_number"
              type="text"
              :placeholder="$t('Withdraw.CardForm.AccountNumber')"
              :class="{ 'is-invalid': errors.length }"
              v-bind="field"
            />

            <ErrorMessage name="form.additional.account_number" class="invalid-feedback" />
          </b-form-group>
        </Field>
      </template>

      <!-- IBAN form -->
      <template v-if="form.payment_type === 1">
        <Field
          name="additional.full_name"
          rules="required"
          :label="$t('Withdraw.CardForm.FullName')"
          v-slot="{ errors, field }"
        >
          <b-form-group class="mb-3 text-muted" :label="$t('Withdraw.CardForm.FullName')">
            <b-form-input
              v-model="form.additional.full_name"
              type="text"
              :placeholder="$t('Withdraw.CardForm.FullName')"
              :class="{ 'is-invalid': errors.length }"
              v-bind="field"
            />

            <ErrorMessage name="additional.full_name" class="invalid-feedback" />
          </b-form-group>
        </Field>

        <Field name="additional.iban" rules="required" label="IBAN" v-slot="{ errors, field }">
          <b-form-group class="mb-3 text-muted" label="IBAN">
            <b-form-input
              v-model="form.additional.iban"
              type="text"
              placeholder="IBAN"
              :class="{ 'is-invalid': errors.length }"
              v-bind="field"
            />

            <ErrorMessage name="additional.iban" class="invalid-feedback" />
          </b-form-group>
        </Field>
      </template>

      <div class="row">
        <div class="col-md-6">
          <Field
            name="additional.country_id"
            rules="required"
            :label="$t('Withdraw.CardForm.Country')"
            v-slot="{ errors, field }"
          >
            <b-form-group class="mb-3" :label="$t('Withdraw.CardForm.Country')">
              <Multiselect
                v-model="form.additional.country_id"
                :options="countries"
                :label="'text'"
                :rtl="$i18n.locale === 'ar'"
                :class="{ 'is-invalid': errors.length }"
                searchable
                v-bind="field"
              />

              <ErrorMessage name="additional.country_id" class="invalid-feedback" />
            </b-form-group>
          </Field>
        </div>

        <div class="col-md-6">
          <Field
            name="additional.city"
            rules="required"
            :label="$t('Withdraw.CardForm.City')"
            v-slot="{ errors, field }"
          >
            <b-form-group class="mb-3" :label="$t('Withdraw.CardForm.City')">
              <b-form-input
                v-model="form.additional.city"
                type="text"
                :placeholder="$t('Withdraw.CardForm.City')"
                :class="{ 'is-invalid': errors.length }"
                v-bind="field"
              />

              <ErrorMessage name="additional.city" class="invalid-feedback" />
            </b-form-group>
          </Field>
        </div>
      </div>

      <Field name="additional.address" :label="$t('Withdraw.CardForm.RecipientAddress')" v-slot="{ errors, field }">
        <b-form-group class="mb-3 text-muted" :label="$t('Withdraw.CardForm.RecipientAddress')">
          <b-form-input
            v-model="form.additional.address"
            type="text"
            :placeholder="$t('Withdraw.CardForm.RecipientAddress')"
            :class="{ 'is-invalid': errors.length }"
            v-bind="field"
          />

          <ErrorMessage name="additional.address" class="invalid-feedback" />
        </b-form-group>
      </Field>

      <div class="text-center mt-4 mb-5">
        <button type="submit" class="btn btn-dark btn-sm w-lg">{{ $t('Common.Continue') }}</button>
      </div>
    </Form>
  </b-overlay>
</template>
